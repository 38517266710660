import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Card } from '@material-ui/core';
import {
  clearData,
  clearFolderImage,
  deleteData,
  deleteTableAll,
  deleteTableLog,
  exportAllData,
  exportData,
  importAllData,
  importData, importDataTest,
} from '../../Api/database';
import { toast } from 'react-toastify';
import './styles.scss';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { tableNewTheme } from '../../ConfigUI/tableTheme';
import moment from 'moment';
import { TOAST_MESSAGE } from '../../Common/constant_text';

const tableLog = [
  'block',
  'blog',
  'change_phone_history',
  'chat_room_admin',
  'chat_room_agent',
  'chat_room_support',
  'chat_room_user',
  'contact',
  'contact_attachments',
  'favorite_job',
  'favorite_user',
  'footprint',
  'image_agent',
  'image_job',
  'image_user',
  'information',
  'job_detail_item',
  'job_entry_member_list',
  'like_list',
  'log_mail_verification',
  'log_send_mail',
  'log_send_push',
  'log_send_sms',
  'member_profile_agent',
  'member_profile_user',
  'message_admin',
  'message_agent',
  'message_support',
  'message_user',
  'otp',
  'payment_log',
  'payment_log_job',
  'point_get_log',
  'point_usage_log',
  'report',
  'salary_log',
  'tag_member_job',
  'tag_member_user_prof',
  'timer_chatroom_standby',
  'timer_mail_member',
  'timer_mail_standby',
  'timer_push_member',
  'timer_push_standby',
  'timer_sms_member',
  'timer_sms_standby',
  'twilio_history',
];

export default function Database() {
  const [data, setData] = useState([]);
  const [item, setItem] = useState(null);
  const history = useHistory();

  useEffect(() => {
    setData([
      { position: 'left', url: '/database/campaign', content: 'キャンペーン設定', name: 'campaign_setting', delete_all: true, clear_folder_image: true },
      { position: 'left', url: '/database/popup-setting', content: 'ポップアップ設定', name: 'popup_setting', delete_all: true, clear_folder_image: true },
      { position: 'left', url: '/database/information', content: 'お知らせ設定', name: 'information', delete_all: true, clear_folder_image: true },
      { position: 'left', url: '/database/contacts', content: '問合せ', name: 'contact', delete_all: true, clear_data: true },
      { position: 'left', url: '', content: 'ポイント消費ログ', name: 'point_usage_log', delete_all: true, clear_data: true },
      { position: 'left', url: '', content: 'ポイント取得ログ', name: 'point_get_log', delete_all: true, clear_data: true },
      { position: 'left', url: '/database/payment', content: '入金ログ', name: 'payment_log', delete_all: true, clear_data: true },
      { position: 'left', url: '/database/payment-log-job', content: '入金ログ(仕事）', name: 'payment_log_job', delete_all: true },
      { position: 'left', url: '/database/message', content: 'チャットログ', name: 'message_user', delete_all: true, clear_data: true, clear_folder_image: true },
      { position: 'left', url: '/database/chat', content: 'チャットルーム管理', name: 'chat_room_user', delete_all: true, clear_data: true },
      { position: 'left', url: '/database/twilio-history', content: 'Twilio通話ログ', name: 'twilio_history', delete_all: true, clear_data: true },
      { position: 'left', url: '/database/otp', content: 'OTPログ', name: 'otp', delete_all: true, clear_data: true },
      { position: 'left', url: '/database/log_mail_verification', content: 'メール認証ログ', name: 'log_mail_verification', delete_all: true, clear_data: true },
      { position: 'left', url: '', content: 'ブロック', name: 'block', delete_all: true, clear_data: true },
      { position: 'left', url: '', content: 'いいね', name: 'like_list', delete_all: true, clear_data: true },
      { position: 'left', url: '', content: 'お気に入り（仕事）', name: 'favorite_job', delete_all: true, clear_data: true },
      { position: 'left', url: '', content: 'お気に入り（Match）', name: 'favorite_user', delete_all: true, clear_data: true },
      { position: 'left', url: '', content: '足跡', name: 'footprint', delete_all: true, clear_data: true },
      { position: 'left', url: '', content: 'プロフ画像（会社）', name: 'image_agent', delete_all: true, clear_folder_image: true },
      { position: 'left', url: '', content: 'プロフ画像（仕事）', name: 'image_job', delete_all: true, clear_folder_image: true },
      { position: 'left', url: '', content: 'プロフ画像（ユーザー）', name: 'image_user', delete_all: true, clear_data: true, clear_folder_image: true },
      { position: 'left', url: '', content: '問い合わせ（画像格納先）', name: 'contact_attachments', delete_all: true, clear_folder_image: true },
      { position: 'left', url: '', content: '送信ログ（メール）', name: 'log_send_mail', delete_all: true, clear_data: true },
      { position: 'left', url: '', content: '送信ログ（プッシュ）', name: 'log_send_push', delete_all: true, clear_data: true },
      { position: 'left', url: '', content: '送信ログ（SMS）', name: 'log_send_sms', delete_all: true, clear_data: true },
      { position: 'left', url: '', content: 'メッセージ（管理×会社）', name: 'message_admin', delete_all: true, clear_folder_image: true },
      { position: 'left', url: '', content: 'メッセージ（会社×ユーザ）', name: 'message_agent', delete_all: true, clear_folder_image: true },
      { position: 'left', url: '', content: 'メッセージ（管理×ユーザー）', name: 'message_support', delete_all: true, clear_data: true, clear_folder_image: true },
      { position: 'left', url: '', content: 'チャットルーム（管理×会社）', name: 'chat_room_admin', delete_all: true },
      { position: 'left', url: '', content: 'チャットルーム（会社×ユーザ）', name: 'chat_room_agent', delete_all: true },
      { position: 'left', url: '', content: 'チャットルーム（管理×ユーザー）', name: 'chat_room_support', delete_all: true, clear_data: true },
      { position: 'left', url: '', content: '仕事応募リスト（ユーザー）', name: 'job_entry_member_list', delete_all: true, clear_data: true },
      { position: 'left', url: '', content: '電話番号変更履歴', name: 'change_phone_history', delete_all: true, clear_data: true },
      { position: 'left', url: '', content: '報告', name: 'report', delete_all: true, clear_data: true },
      { position: 'left', url: '', content: '給与ポイントログ', name: 'salary_log', delete_all: true, clear_data: true },
      { position: 'left', url: '', content: 'タグ登録（ユーザー）', name: 'tag_member_user_prof', delete_all: true, clear_data: true },
      { position: 'left', url: '', content: 'タグ登録（仕事）', name: 'tag_member_job', delete_all: true, clear_data: true },
      { position: 'left', url: '', content: 'ブログ', name: 'blog', delete_all: true, clear_data: true, clear_folder_image: true },
      { position: 'left', url: '', content: '通知設定（ユーザー）', name: 'notification_user', delete_all: true, clear_data: true },
      { position: 'left', url: '', content: 'タイマーメール個別', name: 'timer_mail_member', delete_all: true, clear_data: true },
      { position: 'left', url: '', content: 'タイマーメール一斉', name: 'timer_mail_standby', delete_all: true, clear_data: true },
      { position: 'left', url: '', content: 'タイマーSMS個別', name: 'timer_sms_member', delete_all: true, clear_data: true },
      { position: 'left', url: '', content: 'タイマーSMS一斉', name: 'timer_sms_standby', delete_all: true, clear_data: true },
      { position: 'left', url: '', content: 'タイマープッシュ個別', name: 'timer_push_member', delete_all: true, clear_data: true },
      { position: 'left', url: '', content: 'タイマープッシュ一斉', name: 'timer_push_standby', delete_all: true, clear_data: true },
      { position: 'left', url: '', content: 'タイマーチャットルーム一斉', name: 'timer_chatroom_standby', delete_all: true, clear_data: true },

      { position: 'right', url: '/database/member-admin', content: '管理者', name: 'member_admin', delete_all: false },
      { position: 'right', url: '/database/members', content: 'ユーザー', name: 'member_user', delete_all: false },
      { position: 'right', url: '/database/member-archive', content: 'ユーザー削除一時保存', name: 'member_archive', delete_all: false },
      { position: 'right', url: '/database/operator', content: 'オペレーター', name: 'operator', delete_all: false },
      { position: 'right', url: '/database/agent', content: '会社', name: 'agent', delete_all: false },
      { position: 'right', url: '/database/job-detail', content: '仕事詳細', name: 'job_detail', delete_all: true },
      { position: 'right', url: '', content: '都道府県設定（ユーザー）', name: 'name_state_user', delete_all: true },
      { position: 'right', url: '', content: '都道府県設定（会社）', name: 'name_state_agent', delete_all: true },
      { position: 'right', url: '', content: 'プロフ設定（仕事）', name: 'job_detail_item', delete_all: true },
      { position: 'right', url: '', content: '都道府県設定（仕事）', name: 'name_state_job', delete_all: true },
      { position: 'right', url: '', content: 'プロフ設定（ユーザー）', name: 'name_profile_user', delete_all: true },
      { position: 'right', url: '', content: 'プロフ設定（会社）', name: 'name_profile_agent', delete_all: true },
      { position: 'right', url: '', content: '項目（プロフ）設定（仕事）', name: 'name_item_job', delete_all: true },
      { position: 'right', url: '', content: 'プロフ（ユーザー）', name: 'member_profile_user', delete_all: true },
      { position: 'right', url: '', content: 'プロフ（会社）', name: 'member_profile_agent', delete_all: true },
      { position: 'right', url: '', content: 'タグ設定(ユーザー）', name: 'tag_name_user_prof', delete_all: true },
      { position: 'right', url: '', content: 'タグ設定（仕事）', name: 'tag_name_job', delete_all: true },
      { position: 'right', url: '', content: 'ASP環境ファイル設定', name: 'env_asp', delete_all: true },
      { position: 'right', url: '', content: '番組情報設定', name: 'env_info', delete_all: true },
      { position: 'right', url: '', content: '管理画面表示設定', name: 'env_ui_cms', delete_all: true },
      { position: 'right', url: '', content: 'ユーザー画面表示設定', name: 'env_ui_web', delete_all: true },
      { position: 'right', url: '', content: 'トップメニュー設定', name: 'env_top_nav_web', delete_all: true },
      { position: 'right', url: '', content: '一斉上限・問合メール設定', name: 'env_sys_cms', delete_all: true },
      { position: 'right', url: '', content: '変換タグ設定', name: 'conversion_tag', delete_all: true },
      { position: 'right', url: '', content: 'ユーザーランク設定', name: 'name_rank', delete_all: true },
      { position: 'right', url: '', content: '問い合わせフォームテキスト', name: 'env_constant_text', delete_all: true },
      { position: 'right', url: '', content: 'メールSMSテキスト挿入設定', name: 'env_mailsms_text', delete_all: true },
      { position: 'right', url: '', content: 'ヘルプ（管理画面）', name: 'help_cms', delete_all: true },
      { position: 'right', url: '', content: 'プランレート（停止中）', name: 'plan_rate', delete_all: true },
      { position: 'right', url: '', content: 'ポイント購入レート', name: 'point_rate', delete_all: true },
      { position: 'right', url: '', content: 'ポイント設定', name: 'point_setting', delete_all: true },
      { position: 'right', url: '', content: 'ランク設定', name: 'rank_name', delete_all: true },
      { position: 'right', url: '/database/text-web-page', content: 'Web上部テキスト', name: 'text_web_page', delete_all: true },
      { position: 'right', url: '', content: 'カード設定（管理者）', name: 'tag_name_user_admin', delete_all: true },
    ]);
  }, []);

  const handleExport = (item) => {
    if (!item) {
      exportAllData({ tableNames: data.map((t) => t.name).join(',') }).then(
        (response) => {
          const { data } = response;
          if (data.success) {
            toast.success('エクスポートが完了しました');
            saveToFileText(data.data, 'all_data');
          } else {
            toast.error('失敗しました');
          }
        },
      );
    } else {
      exportData({ tableName: item.name }).then((response) => {
        const { data } = response;
        if (data.success) {
          toast.success('エクスポートが完了しました');
          saveToFileText(data.data, item.name);
        } else {
          toast.error('失敗しました');
        }
      });
    }
  };

  const handleExportDataTableLog = () => {
    exportAllData({ tableNames: tableLog.map((t) => t).join(',') }).then(
      (response) => {
        const { data } = response;
        if (data.success) {
          toast.success('エクスポートが完了しました');
          saveToFileText(data.data, 'all_data_table_log');
        } else {
          toast.error('失敗しました');
        }
      },
    );
  };

  const [file, setFile] = useState();

  const handleFileChange = (e) => {
    if (checkFile(e.target)) {
      if (e.target.files) {
        setFile(e.target.files[0]);
        return
      }
    }

    setFile(null)
  }

  const checkFile = (sender) => {
    const validExits = new Array(".csv");
    let fileExt = sender.value;
    fileExt = fileExt.substring(fileExt.lastIndexOf('.'));

    if (validExits.indexOf(fileExt) < 0) {
      alert("Invalid file selected, valid files are of " + validExits.toString() + " types.");

      return false;
    } else return true;
  }

  const handleExportMemberUser = async (e) => {
    e.preventDefault();
    try {
      if (file) {
        const formData = new FormData();
        formData.append('importFileTest', file);
        let response = await importDataTest(formData);

        const { data } = response;
        if (data.success) {
          toast.success('更新成功');
        } else {
          toast.error('失敗しました');
        }
      }
    } catch (e) {
      if (e.response?.data?.hashCode === 'CSV_INVALID_DATA') {
        try {
          if (e.response?.data.message) {
            const dt = JSON.parse(e.response.data.message);
            let rs = ['失敗しました'];
            for (const r of dt) {
              if (r?.error) {
                rs.push(r?.error);
              }
            }

            toast.error(rs.join('\n'), { enableHtml: true, className: 'toast-new-line' });
            return;
          }
        } catch (e) {
          // TODO
        }
      }
      if (e.response?.data?.errorCode === 'CSV_INVALID_CLOSING_QUOTE') {
        toast.error('失敗しました \n ' + e.response?.data.message, { enableHtml: true, className: 'toast-new-line' });
        return;
      }
      toast.error('失敗しました');
    }
  };

  const handleImport = (item) => {
    document.getElementById('importFile').click();
    setItem(item);
  };

  const handleDelete = (item) => {
    if (!item.disabled_delete) {
      // eslint-disable-next-line no-restricted-globals
      if (confirm('Are you sure you want to delete data table: ' + item.name)) {
        deleteData({ tableName: item.name }).then((response) => {
          const { data } = response;
          if (data.success) {
            toast.success(TOAST_MESSAGE.SUCCESS_DELETE);
          } else {
            toast.error(TOAST_MESSAGE.ERROR_DELETE);
          }
        });
      }
    }
  };

  const handleClearDataUserNotExist = (item) => {
    if (!!item.clear_data) {
      // eslint-disable-next-line no-restricted-globals
      if (confirm('ユーザーがいないログを削除します: ' + item.name)) {
        clearData({ tableName: item.name }).then((response) => {
          const { data } = response;
          if (data.success) {
            toast.success(`${data?.data}${TOAST_MESSAGE.SUCCESS_CLEAR_DATA}`);
          } else {
            toast.error(TOAST_MESSAGE.ERROR_CLEAR_DATA);
          }
        });
      }
    }
  };

  const handleClearFolderImageNotUse = (item) => {
    if (!!item.clear_folder_image) {
      if (window.confirm('Clear folder image not use' + item.name)) {
        clearFolderImage({ tableName: item.name }).then((response) => {
          const { data } = response;
          if (data.success) {
            toast.success(`Folder: ${data?.data?.listFolderDelete} , File: ${data?.data?.listFileDelete} ${TOAST_MESSAGE.SUCCESS_CLEAR_FOLDER_IMAGE}`);
          } else {
            toast.error(TOAST_MESSAGE.ERROR_CLEAR_FOLDER_IMAGE);
          }
        });
      }
    }
  };

  const handleDeleteLog = () => {
    // eslint-disable-next-line no-restricted-globals
    if (confirm('Are you sure you want to delete data all table log')) {
      deleteTableLog().then((response) => {
        const { data } = response;
        if (data.success) {
          toast.success(TOAST_MESSAGE.SUCCESS_DELETE);
        } else {
          toast.error(TOAST_MESSAGE.ERROR_DELETE);
        }
      });
    }
  };

  const handleDeleteAll = () => {
    // eslint-disable-next-line no-restricted-globals
    if (confirm('Are you sure you want to delete data all table')) {
      const dataNameTableDelete = data.filter((val) => !!val.delete_all).map((t) => t.name).join(',')
      deleteTableAll({ tableNames: dataNameTableDelete }).then((response) => {
        const { data } = response;
        if (data.success) {
          toast.success(TOAST_MESSAGE.SUCCESS_DELETE);
        } else {
          toast.error(TOAST_MESSAGE.ERROR_DELETE);
        }
      });
    }
  };

  const saveToFileText = (text, tableName) => {
    const element = document.createElement('a');
    const file = new Blob([text], {
      type: 'text/plain',
    });
    element.href = URL.createObjectURL(file);
    element.download = `${tableName}_${window.location.hostname}_${moment().format('YYYYMMDD')}.json`;
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };

  const handleUploadFile = (e) => {
    const fileSql = document.querySelector('#importFile');
    if (fileSql && fileSql.files && fileSql.files.length > 0) {
      const formData = new FormData();
      formData.append('importFile', fileSql.files[0]);
      if (!item) {
        importAllData(formData).then((response) => {
          const { data } = response;
          if (data.success) {
            toast.success('更新成功');
          } else {
            toast.error('失敗しました');
          }
        });
      } else {
          formData.append('tableName', item.name);
          if(!fileSql.files[0]?.name.includes(item?.name)){
            alert(`インポートするファイルが間違っています`)
          }else{
            alert(`${fileSql.files[0]?.name} をインポートします`)
            importData(formData).then((response) => {
              const { data } = response;
              if (data.success) {
                toast.success('更新成功');
              } else {
                toast.error('失敗しました');
              }
            });
          }
      }
    }
  };

  const redirectLink = (url) => {
    if (url) {
      history.push(url);
    }
  };

  const renderButtonAction = (item) => {
    return (
      <div className='button-column'>
        {
          !!item.url &&
          (
            <Button
              className="btnReportDatabase"
              variant="contained"
              onClick={() => {
                redirectLink(item.url);
              }}
            >
              表示
            </Button>
          )
        }
        <Button
          className="btnReportDatabase"
          variant="contained"
          onClick={() => {
            handleExport(item);
          }}
        >
          Export
        </Button>
        <Button
          className="btnReportDatabase"
          variant="contained"
          onClick={() => {
            handleImport(item);
          }}
        >
          Import
        </Button>
        {
          !!item.clear_folder_image && (
            <Button
              className="btnDeleteDatabase"
              variant="contained"
              onClick={() => {
                handleClearFolderImageNotUse(item);
              }}
            >
              Clear_IMG
            </Button>
          )
        }
        {
          !!item.clear_data && (
            <Button
              className="btnDeleteDatabase"
              variant="contained"
              onClick={() => {
                handleClearDataUserNotExist(item);
              }}
            >
              Record_D
            </Button>
          )
        }
        <Button
          className="btnDeleteDatabase"
          variant="contained"
          onClick={() => {
            handleDelete(item);
          }}
        >
          削除
        </Button>
      </div>
    );
  };

  const datahead = data.filter((t) => t.position === 'left').slice(0, 50);
  const dataend = data.filter((t) => t.position === 'right').slice(0, 50);

  return (
    <div>
      <div
        style={{
          marginTop: 20,
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <div style={{ width: '50%', display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ paddingTop: 10, paddingLeft: 11 }}>
            <span>大項目 240912</span>
          </div>
          <Button
            className=""
            variant="contained"
            onClick={handleExportMemberUser}
          >
            Import csv
          </Button>
          <input type={"file"} accept={".csv"} onChange={handleFileChange} />

          <div style={{ paddingRight: 6 }}>
            <Button
              className="btnReportDatabase"
              variant="contained"
              onClick={() => {
                handleExport(null);
              }}
            >
              Export
            </Button>
            <Button
              className="btnReportDatabase"
              variant="contained"
              onClick={() => {
                handleImport(null);
              }}
            >
              Import
            </Button>
            <Button
              className="btnDeleteDatabase"
              variant="contained"
              onClick={() => {
                handleDeleteAll();
              }}
            >
              Delete
            </Button>
          </div>
        </div>
        <div style={{ width: '50%', display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ paddingTop: 10, paddingLeft: 11 }}>
            <span>ログ系Tableの削除</span>
          </div>
          <div style={{ paddingRight: 6 }}>
            <Button
              className="btnReportDatabase"
              variant="contained"
              onClick={() => {
                handleExportDataTableLog(null);
              }}
            >
              Export
            </Button>
            <Button
              className="btnReportDatabase"
              variant="contained"
              onClick={() => {
                handleImport(null);
              }}
            >
              Import
            </Button>
            <Button
              className="btnDeleteDatabase"
              variant="contained"
              onClick={() => {
                handleDeleteLog();
              }}
            >
              Delete
            </Button>
          </div>
        </div>
      </div>

      <MuiThemeProvider theme={tableNewTheme}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: 20,
          }}
        >
          <Card style={{ width: '49.3%' }} className='scroll-x-on-top'>
            <Table padding="checkbox" className="table mb-0">
              <TableBody>
                <TableRow>
                  <TableCell>Content</TableCell>
                  <TableCell>Table名</TableCell>
                  <TableCell className="data-column"></TableCell>
                </TableRow>
                {datahead.map((item, key) => (
                  <TableRow key={key}>
                    <TableCell>{item.content}</TableCell>
                    <TableCell>{item.name}</TableCell>
                    <TableCell className="button-column">{renderButtonAction(item)}</TableCell>
                  </TableRow>
                ))}
                {datahead?.length < 20 &&
                Array.from({ length: 20 - datahead?.length }, (v, i) => (
                  <TableRow key={i}>
                    <TableCell>&nbsp;</TableCell>
                    <TableCell>&nbsp;</TableCell>
                    <TableCell>&nbsp;</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Card>
          <Card style={{ width: '49.3%' }}>
            <Table padding="checkbox" className="table mb-0">
              <TableBody>
                <TableRow>
                  <TableCell>Content</TableCell>
                  <TableCell>Table名</TableCell>
                  <TableCell className="data-column"></TableCell>
                </TableRow>
                {dataend.map((item, key) => (
                  <TableRow key={key}>
                    <TableCell>{item.content}</TableCell>
                    <TableCell>{item.name}</TableCell>
                    <TableCell>{renderButtonAction(item)}</TableCell>
                  </TableRow>
                ))}
                {dataend?.length < 22 &&
                Array.from({ length: 22 - dataend?.length }, (v, i) => (
                  <TableRow key={i}>
                    <TableCell>&nbsp;</TableCell>
                    <TableCell>&nbsp;</TableCell>
                    <TableCell>&nbsp;</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Card>
        </div>
      </MuiThemeProvider>
      <input
        id="importFile"
        name="importFile"
        type="file"
        onChange={handleUploadFile}
        style={{ display: 'none' }}
      />
    </div>
  );
}
