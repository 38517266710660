import { userDetailMessages } from '../../Common/information';
import { toast } from 'react-toastify';

export const copyToClipboard = (text) => () => {
  function fallbackCopyTextToClipboard(text) {
    const textArea = document.createElement('textarea');
    textArea.value = text;

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      document.execCommand('copy');
      toast.success(userDetailMessages.COPY_ID_SUCCESS);

    } catch (err) {
      toast.error(userDetailMessages.COPY_ID_FAILED);
    }

    document.body.removeChild(textArea);
  }

  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text);
    return;
  }

  navigator.clipboard.writeText(text).then(function() {
    toast.success(userDetailMessages.COPY_ID_SUCCESS);
  }, function(err) {
    toast.error(userDetailMessages.COPY_ID_FAILED);
  });
};
