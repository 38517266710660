import {
  Card,
  MuiThemeProvider,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';
import SortArrows from '../../../Components/SortArrows';
import { tableNewTheme } from '../../../ConfigUI/tableTheme';
import Util from '../../../Util/Util';
import { convertTimerSendingTypeText } from '../../../Util/utils';
import { scheduleTimerOptions } from '../../../Common/constant_text';

function TimerMailList({
  logSendMail = [],
  pageIndex,
  pageSize,
  loading,
  filter = { sort: {} },
  onSort = (sortByKey) => {},
  handleClickDetail,
}) {
  return (
    <div className="align-top">
      <MuiThemeProvider theme={tableNewTheme}>
        <Card className="scroll-x-on-top">
          <Table padding="checkbox" className="table mb-0">
            <TableBody>
              <TableRow>
                <TableCell>No</TableCell>
                <TableCell>label</TableCell>
                <TableCell>category</TableCell>
                <TableCell>send_time</TableCell>
                <TableCell>last_send_date</TableCell>
                <TableCell>created_date</TableCell>
                <TableCell>from_address</TableCell>
                <TableCell>sender_id</TableCell>
                <TableCell>type</TableCell>
                <TableCell>cronjob_status</TableCell>
                <TableCell>send_status</TableCell>
                <TableCell>×送信想定数</TableCell>
                <TableCell>×ボタン</TableCell>
              </TableRow>

              {logSendMail.map((item, index) => (
                <TableRow key={item.id}>
                  <TableCell>
                    {(pageIndex - 1) * pageSize + index + 1}
                  </TableCell>

                  <TableCell>{item.label}</TableCell>
                  <TableCell>
                    {
                      scheduleTimerOptions.find(
                        (e) => e.value === +item.category,
                      )?.label
                    }
                  </TableCell>
                  <TableCell>
                    {
                      Util.timestampToDate(item.sendTime, 'YYYY/MM/DD HH:mm:ss')
                      // convertTimerSendingTypeText(item?.type)
                    }
                  </TableCell>
                  <TableCell>
                    <time>
                      {Util.timestampToDate(
                        item.lastSentDate,
                        'YYYY/MM/DD HH:mm:ss',
                      )}
                    </time>
                  </TableCell>
                  <TableCell>
                    <time>
                      {Util.timestampToDate(
                        item.createdDate,
                        'YYYY/MM/DD HH:mm:ss',
                      )}
                    </time>
                  </TableCell>
                  <TableCell>{item.fromAddress}</TableCell>
                  <TableCell>{item.sender_id}</TableCell>
                  <TableCell>{item.type}</TableCell>
                  <TableCell>{item.cronjobStatus}</TableCell>
                  <TableCell>{item.sendStatus}</TableCell>
                  <TableCell>{}</TableCell>
                  <TableCell
                    className="d-color-link"
                    style={{ cursor: 'pointer' }}
                  >
                    {
                      // <Link onClick={() => handleClickDetail(item)}>表示</Link>
                    }
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Card>
      </MuiThemeProvider>
    </div>
  );
}

export default TimerMailList;
